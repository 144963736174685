import Head from 'next/head'
import { useTranslation } from 'next-i18next'
import {
	composeSSP,
	withTranslations,
	withDate,
	withAuth
} from '@vidbaseapp/edit.vidbase/src/app/middleware'
import {
	LandingLayout,
	LandingHeader,
	LandingSplash,
	LandingScreenshot,
	LandingLogoCloud,
	LandingFeatures,
	LandingIntegrations,
	LandingProductions,
	LandingSignup,
	LandingFooter
} from '~/app/components'

import type { GetServerSidePropsContext } from 'next'

const IndexPage = ({ ts, session = null, ...props }) => {
	const { t } = useTranslation()

	return (
		<LandingLayout>
			<Head>
				<title>{t('common:title')}</title>
			</Head>
			<LandingHeader session={session} />
			<LandingSplash />
			<LandingLogoCloud />
			<LandingFeatures />
			<LandingIntegrations />
			<LandingProductions />
			<LandingSignup />
			<LandingFooter year={new Date(ts).getFullYear()} />
		</LandingLayout>
	)
}

export const getStaticProps = async (ctx: GetServerSidePropsContext) => {
	return await composeSSP([
		// withAuth,
		withTranslations(['404', '502', 'common', 'landing', 'locales', 'login', 'nav']),
		withDate()
	])(ctx)
}

export default IndexPage
