import { styled } from '@linaria/react';
import { StyledLandingSection } from './LandingSection';
import { StyledLogoCloud } from './LandingLogoCloud';
export const LandingIntegrations = () => {
  return <StyledLandingSection className="integrations">
			<div className="content">
				<div className="headline">
					<h2>Integrations</h2>
					<p>Plays well with others. Integrate as much or as little as you want.</p>
				</div>
				<StyledLogoCloud>
					<div>
						<div className="logoFig">
							<img src="/img/vidbase-studios-logo.svg" alt="vidbase.studio" width="312" height="130" />
						</div>
					</div>
					<div>
						<div className="logoFig">
							<img src="/img/vidbase-studios-logo.svg" alt="vidbase.studio" width="312" height="130" />
						</div>
					</div>
					<div>
						<div className="logoFig">
							<img src="/img/vidbase-studios-logo.svg" alt="vidbase.studio" width="312" height="130" />
						</div>
					</div>
				</StyledLogoCloud>
			</div>
		</StyledLandingSection>;
};